import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';
import {
    HiltiApplicationEntity, HubEntity, LanguageEntity, RegionEntity, RegionLanguageEntity
} from './code-lists.service';

export interface ReleaseNote {
    ReleaseNoteId: number;
    ReleaseNoteVersionId: number;
    Title: string;
    Content: string;
    Culture: string;
    ReleaseVersion: string;
    ReleaseDate: string;
}

export interface ReleaseNotesListReleaseNotesRequest {
    searchTitle: string;
    searchReleaseVersion: string;
    searchReleaseDate: string;
}

export interface ReleaseNotesListReleaseNotesResponse {
    releaseNotes: ReleaseNote[];
}

export interface ReleaseNotesDeleteReleaseNoteRequest {
    releaseNoteVersionId: number;
}

export interface ReleaseNotesExportReleaseNote {
    releaseNoteId: number;
    releaseNoteVersionId: number;
}

export interface ReleaseNotesImportReleaseNotesRequest {
    ReleaseNoteVersionId: number;
    Content: string;
}

export interface ReleaseNotesListReleaseNoteTranslationsRequest {
    releaseNoteVersionId: number;
}

export interface ReleaseNotesListReleaseNoteTranslationsResponse {
    releaseNotes: ReleaseNote[];
}

export enum MarketingCampaignType {
    Video = 0,
    Image = 1
}

export interface MarketingCampaignListMarketingCampaignsRequest {
    name: string;
    hubs: number[];
    campaignType: MarketingCampaignType;
    regions: number[];
    isActive: boolean;
}

export interface MarketingCampaignListMarketingCampaignsResponse {
    marketingCampaigns: MarketingCampaign[];
}

export interface MarketingCampaign {
    MarketingCampaignId: number;
    Name: string;
    ActiveFrom: string;
    ActiveTo: string;
    CampaignType: MarketingCampaignType;
    InAppPlacement: boolean;
    DisplayOnLaunch: boolean;
    Hubs: MarketingCampaignHub[];
    PostalCodes: string[];
    IsActive: boolean;
    UserLicenseOptions: UserLicenseOptions;
    Ordinal: MarketingCampaignOrdinal;
    Emails: MarketingCampaignEmail[];
    ForNewRegisteredUser: boolean;
}

export enum UserLicenseOptions {
    Trial = 1 << 0,
    Standard = 1 << 1,
    Premium = 1 << 2
}

export enum MarketingCampaignOrdinal {
    First = 1,
    Second = 2
}

export interface MarketingCampaignEmail {
    MarketingCampaignEmailId: number;
    Email: string;
}

export interface MarketingCampaignHub {
    HubId: number;
    Regions: MarketingCampaignHubRegion[];
}

export interface MarketingCampaignHubRegion {
    RegionId: number;
    Url: string;
    Link: string;
    Enabled: boolean;
    RegionLanguages?: MarketingCampaignHubRegionLanguage[];
    Title: string;
    Description: string;
}

export interface MarketingCampaignHubRegionLanguage {
    LcId: number;
    Url: string;
    Link: string;
    Enabled: boolean;
    Title: string;
    Description: string;
}

export interface MarketingCampaignPostalCode {
    Id: string,
    Name: string
}

export interface MarketingCampaignDeleteMarketingCampaignRequest {
    marketingCampaignId: number;
}

export interface MarketingCampaignUpdateMarketingCampaignRequest {
    MarketingCampaignId: number;
    Name: string;
    ActiveFrom: string;
    ActiveTo: string;
    CampaignType: MarketingCampaignType;
    DisplayOnLaunch: boolean;
    Hubs: MarketingCampaignHub[];
}

export interface MarketingCampaignAddMarketingCampaignRequest {
    MarketingCampaignId: number;
    Name: string;
    ActiveFrom: string;
    ActiveTo: string;
    CampaignType: MarketingCampaignType;
    DisplayOnLaunch: boolean;
    Hubs: MarketingCampaignHub[];
}

export interface MarketingCampaignImportMarketingCampaignEmailsRequest {
    MarketingCampaignId: number;
    Content: string;
}

export interface CodeListsResponse {
    Regions: RegionEntity[];
    RegionLanguages: RegionLanguageEntity[];
    Hubs: HubEntity[];
    Languages: LanguageEntity[];
    HiltiApplications: HiltiApplicationEntity[];
}

export interface RegionLinks {
    RegionId: number;
    AskHiltiLink: string;
    BetLearningLink: string;
    BuyLicenseHolLink: string;
    HandrailCBFEMLearnMoreLink: string;
    LearningImage: string;
    LicenseComparisonPageLink: string;
    ManageAccountLink: string;
    PerfectSpecLink: string;
    SoftwareSellingLink: string;
    UpgradeToPremiumHeaderLink: string;
    UpgradeToPremiumLink: string;
}

export interface RegionLinksSearchRequest {
    RegionId: number;
}

export interface RegionLinksModel {
    RegionId: number;
    AskHiltiLink: string;
    BetLearningLink: string;
    BuyLicenseHolLink: string;
    HandrailCBFEMLearnMoreLink: string;
    LearningImage: string;
    LicenseComparisonPageLink: string;
    ManageAccountLink: string;
    PerfectSpecLink: string;
    SoftwareSellingLink: string;
    UpgradeToPremiumHeaderLink: string;
    UpgradeToPremiumLink: string;
    RegionLinksTranslations: RegionLinksTranslationsModel[];
}

export interface RegionLinksTranslationsModel {
    RegionId: number;
    LCID: number;
    UpgradeToPremiumText: string;
    UpgradeToPremiumHeaderText: string;
}

export interface HiltiApplication {
    Id: number;
    Active: boolean;
    DisplayKey: string;
    SubtitleKey: string;
    SortOrder: number;
    Image: string;
    ShowNewLabel: boolean;
    PerHubAccess: boolean;
    Url: string;
    UserAccessEmails: string;
    Links: HiltiApplicationLinks[];
}

export interface HiltiApplicationLinks {
    RegionId: number;
    Link: string;
    Active: boolean;
    LcId: number;
}

export interface ExternalApp {
    Id: number;
    Name: string;
    TranslationKey: string;
    Image: string;
    ShowNewLabel: boolean;
    Links: ExternalAppLinks[];
}

export interface ExternalAppLinks {
    RegionId: number;
    Link: string;
    Active: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ProductInformationApiService {
    constructor(private apiService: ApiService) { }


    async releaseNotesEditReleaseNote(request: ReleaseNote, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('PUT', environment.productInformationAdminWebServiceUrl + 'ReleaseNotes/EditReleaseNote', request);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async releaseNotesListReleaseNotes(request: ReleaseNotesListReleaseNotesRequest, apiOptions?: ApiOptions): Promise<ReleaseNotesListReleaseNotesResponse> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'ReleaseNotes/ListReleaseNotes', {
            params: new HttpParams({ fromObject: request as any })
        });

        const releaseNotes = await this.apiService.request<ReleaseNote[]>(httpRequest, apiOptions);
        return {
            releaseNotes
        };
    }

    async releaseNotesDeleteReleaseNote(request: ReleaseNotesDeleteReleaseNoteRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('DELETE', environment.productInformationAdminWebServiceUrl + `ReleaseNotes/DeleteReleaseNote/${encodeURIComponent(request.releaseNoteVersionId.toString())}`);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async releaseNotesExportReleaseNote(request: ReleaseNotesExportReleaseNote, apiOptions?: ApiOptions): Promise<Blob> {
        const httpRequest = new HttpRequest('GET',
            environment.productInformationAdminWebServiceUrl + `ReleaseNotes/ExportReleaseNote/${encodeURIComponent(request.releaseNoteId.toString())}/${encodeURIComponent(request.releaseNoteVersionId.toString())}`,
            { responseType: 'blob' }
        );

        return await this.apiService.request<Blob>(httpRequest, apiOptions);
    }

    async releaseNotesImportReleaseNotes(request: ReleaseNotesImportReleaseNotesRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'ReleaseNotes/ImportReleaseNotes', request);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async releaseNotesListReleaseNoteTranslations(request: ReleaseNotesListReleaseNoteTranslationsRequest, apiOptions?: ApiOptions): Promise<ReleaseNotesListReleaseNoteTranslationsResponse> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + `ReleaseNotes/ListReleaseNoteTranslations/${encodeURIComponent(request.releaseNoteVersionId.toString())}`);

        const releaseNotes = await this.apiService.request<ReleaseNote[]>(httpRequest, apiOptions);
        return {
            releaseNotes
        };
    }

    async releaseNotesAddReleaseNote(request: ReleaseNote, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'ReleaseNotes/AddReleaseNote', request);

        await this.apiService.request(httpRequest, apiOptions);
    }


    async marketingCampaignListMarketingCampaigns(request: MarketingCampaignListMarketingCampaignsRequest, apiOptions?: ApiOptions): Promise<MarketingCampaignListMarketingCampaignsResponse> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'MarketingCampaign/ListMarketingCampaigns', {
            params: new HttpParams({ fromObject: request as any })
        });

        const marketingCampaigns = await this.apiService.request<MarketingCampaign[]>(httpRequest, apiOptions);
        return {
            marketingCampaigns
        };
    }

    async marketingCampaignDeleteMarketingCampaign(request: MarketingCampaignDeleteMarketingCampaignRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('DELETE', environment.productInformationAdminWebServiceUrl + `MarketingCampaign/DeleteMarketingCampaign/${encodeURIComponent(request.marketingCampaignId.toString())}`);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async marketingCampaignUpdateMarketingCampaign(request: MarketingCampaignUpdateMarketingCampaignRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('PUT', environment.productInformationAdminWebServiceUrl + 'MarketingCampaign/UpdateMarketingCampaign', request);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async marketingCampaignAddMarketingCampaign(request: MarketingCampaignAddMarketingCampaignRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'MarketingCampaign/AddMarketingCampaign', request);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async marketingCampaignImportMarketingCampaignEmails(request: MarketingCampaignImportMarketingCampaignEmailsRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'MarketingCampaign/ImportMarketingCampaignEmails', request);

        await this.apiService.request(httpRequest, apiOptions);
    }


    async getCodeLists(apiOptions?: ApiOptions): Promise<CodeListsResponse> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'CodeLists/list');

        return await this.apiService.request<CodeListsResponse>(httpRequest, apiOptions);
    }


    async regionLinksListRegionLinks(request: RegionLinksSearchRequest, apiOptions?: ApiOptions): Promise<RegionLinks[]> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'RegionLinks/ListRegionLinks', {
            params: new HttpParams({ fromObject: request as any })
        });

        return await this.apiService.request<RegionLinks[]>(httpRequest, apiOptions);
    }

    async regionLinksGetRegionLinks(regionId: number, apiOptions?: ApiOptions): Promise<RegionLinksModel> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + `RegionLinks/GetRegionLinks/${encodeURIComponent(regionId)}`);

        return await this.apiService.request<RegionLinksModel>(httpRequest, apiOptions);
    }

    async regionLinksEditRegionLinks(request: RegionLinksModel, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('PUT', environment.productInformationAdminWebServiceUrl + 'RegionLinks/EditRegionLinks', request);

        await this.apiService.request(httpRequest, apiOptions);
    }


    async anchorAiGetUserAccess(apiOptions?: ApiOptions): Promise<{ Emails: string, Regions: string }> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'AnchorAi/GetUserAccess');

        return (await this.apiService.request<{ Emails: string, Regions: string }>(httpRequest, apiOptions))
    }

    async anchorAiUpdateUserAccess(emails: string, regions:string, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'AnchorAi/UpdateUserAccess', { Emails: emails, Regions: regions });

        await this.apiService.request(httpRequest, apiOptions);
    }


    async getHiltiApplications(apiOptions?: ApiOptions): Promise<HiltiApplication[]> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'HiltiApplications/GetApplications');

        return await this.apiService.request<HiltiApplication[]>(httpRequest, apiOptions);
    }

    async hiltiApplicationsUpdateApplications(hiltiApplications: HiltiApplication, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'HiltiApplications/UpdateApplication', hiltiApplications);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async addHiltiApps(hiltiApplications: HiltiApplication, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'HiltiApplications/AddApplication', hiltiApplications);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async deleteHiltiApp(id: number, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('DELETE', environment.productInformationAdminWebServiceUrl + 'HiltiApplications/DeleteApplication/' + id);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async getExternalApps(apiOptions?: ApiOptions): Promise<ExternalApp[]> {
        const httpRequest = new HttpRequest('GET', environment.productInformationAdminWebServiceUrl + 'QuickstartExternalApps/GetQuickstartExternalApps');

        return await this.apiService.request<ExternalApp[]>(httpRequest, apiOptions);
    }

    async updateExternalApps(hiltiApplications: ExternalApp, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'QuickstartExternalApps/UpdateApplication', hiltiApplications);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async addExternalApps(hiltiApplications: ExternalApp, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', environment.productInformationAdminWebServiceUrl + 'QuickstartExternalApps/AddApplication', hiltiApplications);

        await this.apiService.request(httpRequest, apiOptions);
    }

    async deleteExternalApp(id: number, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('DELETE', environment.productInformationAdminWebServiceUrl + 'QuickstartExternalApps/DeleteQuickstartExternalApp/' + id);

        await this.apiService.request(httpRequest, apiOptions);
    }
}
