import { Component, OnInit } from '@angular/core';

import { Hub, HubRegionLink, HubsFilterItem } from '../../entities/hubs-regions';
import { ImageHelper } from '../../helpers/image-helper';
import { AppTitleService } from '../../services/app-title.service';
import { CodeListsService, RegionEntity } from '../../services/code-lists.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import {
    HiltiApplication, HiltiApplicationLinks, ProductInformationApiService
} from '../../services/product-information-api.service';
import { Page } from '../page';
import hiltiApplicationsAddDialogTemplate from './hilti-applications-add-dialog.hbs';
import hiltiApplicationsAddLinksDialogTemplate from './hilti-applications-add-links-dialog.hbs';
import hiltiApplicationsEditDialogTemplate from './hilti-applications-edit-dialog.hbs';
import hiltiApplicationsPreviewDialogTemplate from './hilti-applications-preview-dialog.hbs';

interface RegionLanguage {
    Id: number;
    Name: string;
    LcId: number;
    RegionId: number;
    DefaultForRegion: boolean;
}

@Component({
    selector: 'app-hilti-applications',
    templateUrl: './hilti-applications.component.html',
    styleUrls: ['./hilti-applications.component.css']
})
export class HiltiApplicationsComponent extends Page implements OnInit {
    pageTitle = $localize`:app-title hilti applications|Hilti applications title:Hilti applications`;
    public emailsPlaceholder = '';
    public regions: RegionEntity[] = [];

    private initialData: HiltiApplication[];
    private selectedHubs: number[] = [];
    private hubRegionCodeList: HubRegionLink[] = [];
    private hubCodeList: Hub[] = [];
    private regionLanguageCodeList: RegionLanguage[] = [];

    private $hiltiApplicationsGrid: JQuery<HTMLElement>;
    private $hiltiApplicationsDialog: JQuery<HTMLElement>;
    private $hiltiApplicationsLinksDialog: JQuery<HTMLElement>;
    private image: string;
    private hubsFilter: HubsFilterItem[] = [];

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private productInformationApiService: ProductInformationApiService,
        private codeListsService: CodeListsService,
        private modalService: ModalService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        this.emailsPlaceholder = "some.email@example.com\nsome.other.email@example.com\n...";

        this.$hiltiApplicationsGrid = $('#hiltiApplicationsGrid');
        this.$hiltiApplicationsDialog = $('#hiltiApplicationsDialog');
        this.$hiltiApplicationsLinksDialog = $('#hiltiApplicationsLinksDialog');

        this.initGrid();
        await this.loadGridData();
    }

    addApp() {
        const compiledHtml = hiltiApplicationsAddDialogTemplate({
            ApplicationName: '',
            DisplayApplicationName: this.getAppTranslationKey('<key>'),
            ApplicationSubtitle: '',
            DisplayApplicationSubtitle: this.getAppTranslationKey('<key>'),
            Image: ImageHelper.emptyImage,
            AccessSubformClass: this.getAccessSubformClass(true),
            ActivatedPerHub: 'checked',
            PerUserAccessUrl: '',
            UserAccessEmailsPlaceholder: this.emailsPlaceholder,
            UserAccessEmails: '',
            Hubs: this.hubCodeList,
            HubsFilter: this.hubsFilter
        });

        this.$hiltiApplicationsDialog.html(compiledHtml);

        this.$hiltiApplicationsDialog.dialog({
            title: 'Add Hilti application',
            width: '1000px',
            modal: true,
            resizable: false,
            draggable: false,
            buttons: [
                {
                    text: 'Cancel',
                    click: () => {
                        this.$hiltiApplicationsDialog.dialog('close');
                    }
                },
                {
                    text: 'Save',
                    click: async () => {
                        const $form = this.$hiltiApplicationsDialog.find('form');
                        const form = $form[0] as any;

                        if (form.reportValidity == null || form.reportValidity()) {
                            if (ImageHelper.isImageEmpty(this.image)) {
                                this.modalService.showMessage('Please select application image.', 'No image specified');
                                return;
                            }

                            let sortOrder = 0;
                            this.initialData.forEach(a => {
                                if (a.SortOrder > sortOrder) {
                                    sortOrder = a.SortOrder;
                                }
                            });

                            const data: HiltiApplication = {
                                Id: -1,
                                Active: form.elements['active'].checked,
                                DisplayKey: form.elements['applicationName'].value,
                                SubtitleKey: form.elements['applicationSubtitle'].value,
                                SortOrder: sortOrder + 1,
                                Image: this.image,
                                ShowNewLabel: form.elements['showNewLabel'].checked,
                                PerHubAccess: form.elements['activatedPerHub'].checked,
                                Url: form.elements['perUserAccessUrl'].value,
                                UserAccessEmails: form.elements['userAccessEmails'].value,
                                Links: this.getAppLinks($form)
                            };

                            this.productInformationApiService.addHiltiApps(data).then( async () => {
                                this.$hiltiApplicationsDialog.dialog('close');
                                this.image = null;
                                await this.loadGridData();
                            });
                        }
                    }
                }
            ],
            open: () => {
                this.$hiltiApplicationsDialog.find('#hubsFilter')
                    .chosen({ width: '100%', placeholder_text_multiple: 'Select hubs ...' })
                    .on('change', (eventObject, args) => this.hubsFilterChange(eventObject, args, null, true));
                this.$hiltiApplicationsDialog.find('#image').on('change', (eventObject, args) => this.handleImage());
                this.$hiltiApplicationsDialog.find('#applicationName').on('keyup', (e) => this.onApplicationNameTranslationKeyPartChange());
                this.$hiltiApplicationsDialog.find('#applicationSubtitle').on('keyup', (e) => this.onApplicationSubtitleTranslationKeyPartChange());
                this.$hiltiApplicationsDialog.find('#activatedPerHub').on('change', (e) => this.handleActivatedPerHubChanged());
            },
            close: () => {
                this.$hiltiApplicationsDialog.empty();
            }
        });
    }

    private initGrid() {
        this.$hiltiApplicationsGrid.jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 5,
            fields: [
                { name: 'HiltiApplicationId', type: 'number', visible: false },
                { name: 'Image', type: 'image', width: 200, title: 'Image', align: 'left', itemTemplate:(value: string, _item: HiltiApplication) => `<img src="${value}">` },
                { title: 'Name', type: 'text', width: 200, align: 'left', itemTemplate: (_value: void, item: HiltiApplication) => item.DisplayKey },
                { title: 'Translation keys', type: 'text', width: 'auto', align: 'left',
                    itemTemplate: (_value: void, item: HiltiApplication) => {
                        let text = `<div>${this.getAppTranslationKey(item.DisplayKey)}</div><div class="small">`;
                        if ((item.SubtitleKey ?? '').trim().length > 0) {
                            text += this.getAppTranslationKey(item.SubtitleKey);
                        }
                        else {
                            text += '/';
                        }

                        text += '</div>';
                        return text;
                    }
                },
                { title: 'Show "New" label', type: 'text', width: 180, align: 'left', itemTemplate: (_value: void, item: HiltiApplication) => item.ShowNewLabel.toString() },
                { title: 'Active', type: 'text', width: 180, align: 'left', itemTemplate: (_value: void, item: HiltiApplication) => item.Active.toString() },
                { title: 'Activated for', type: 'text', width: 180, align: 'left', itemTemplate: (_value: void, item: HiltiApplication) => item.PerHubAccess ? 'Per hub for all users' : "Per email list" },
                {
                    type: 'control',
                    width: 150,
                    headerTemplate: 'Actions',
                    itemTemplate: (value: void, item: HiltiApplication) => {
                        const $mainNode = $('<div>').addClass('js-grid-actions');
                        const $viewNode = $('<span>').addClass('sprite d-inline-block sprite-view').attr('title', 'Preview').appendTo($mainNode);
                        const $editNode = $('<span>').addClass('sprite d-inline-block sprite-edit').attr('title', 'Edit').appendTo($mainNode);
                        const $deleteNode = $('<span>').addClass('sprite d-inline-block sprite-delete').attr('title', 'Delete').appendTo($mainNode);

                        $viewNode.click(() => {
                            const compiledHtml = hiltiApplicationsPreviewDialogTemplate({
                                Active: item.Active,
                                ApplicationName: this.getAppTranslationKey(item.DisplayKey),
                                ApplicationSubtitle: this.getAppTranslationKey(item.SubtitleKey),
                                Image: item.Image,
                                ShowNewLabel: item.ShowNewLabel,
                                ActivatedPerHub: item.PerHubAccess
                            });

                            this.$hiltiApplicationsDialog.html(compiledHtml);

                            this.$hiltiApplicationsDialog.dialog({
                                title: 'Preview Hilti application',
                                width: '1000px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Close',
                                        click: () => {
                                            this.$hiltiApplicationsDialog.dialog('close');
                                        }
                                    }
                                ],
                                close: () => {
                                    this.$hiltiApplicationsDialog.empty();
                                }
                            });
                        });

                        $editNode.click(async () => {
                            const hiltiApplication = this.initialData.find(x => x.Id == item.Id);
                            const regions = hiltiApplication.Links.map(x => x.RegionId);
                            const hubs: Hub[] = [];

                            this.hubCodeList.forEach(x => {
                                let hubsFilterItem = this.hubsFilter.find(hfi => hfi.HubId == x.Id);
                                if (hubsFilterItem == null) {
                                    hubsFilterItem = {
                                        HubId: x.Id,
                                        Name: x.Name,
                                        Selected: null
                                    };
                                    this.hubsFilter.push(hubsFilterItem);
                                }

                                x.Regions.forEach(y => {
                                    if (regions.includes(y.Id) && hubs.find(h => h.Id == x.Id) == null) {
                                        hubs.push(x);
                                    }
                                });

                                hubsFilterItem.Selected = (hubs.find(h => h.Id == x.Id) == null) ? null : 'selected';
                            });

                            this.hubRegionCodeList.forEach(x => ({
                                Id: x.Id,
                                Name: x.Name,
                                HubId: x.HubId,
                                Link: hiltiApplication.Links.find(y => y.RegionId == x.Id)?.Link,
                                Enabled: hiltiApplication.Links.find(y => y.RegionId == x.Id)?.Active ? 'checked' : ''
                            }));

                            const compiledHtml = hiltiApplicationsEditDialogTemplate({
                                Active: hiltiApplication.Active ? 'checked' : null,
                                ApplicationName: hiltiApplication.DisplayKey,
                                DisplayApplicationName: this.getAppTranslationKey(hiltiApplication.DisplayKey),
                                ApplicationSubtitle: hiltiApplication.SubtitleKey,
                                DisplayApplicationSubtitle: this.getAppTranslationKey(hiltiApplication.SubtitleKey),
                                Image: hiltiApplication.Image,
                                ShowNewLabel: hiltiApplication.ShowNewLabel ? 'checked' : null,
                                ActivatedPerHub: hiltiApplication.PerHubAccess ? 'checked' : null,
                                AccessSubformClass: this.getAccessSubformClass(hiltiApplication.PerHubAccess),
                                PerUserAccessUrl: hiltiApplication.Url,
                                UserAccessEmailsPlaceholder: this.emailsPlaceholder,
                                UserAccessEmails: hiltiApplication.UserAccessEmails,
                                Hubs: this.hubCodeList.map(x => ({
                                    Id: x.Id,
                                    Name: x.Name,
                                    Regions: this.hubRegionCodeList.filter(y => y.HubId == x.Id)
                                })),
                                HubsFilter: this.hubsFilter
                            });

                            this.$hiltiApplicationsDialog.html(compiledHtml);

                            const compiledHtmlLinks = hiltiApplicationsAddLinksDialogTemplate({
                                Regions: this.hubRegionCodeList
                            });

                            hubs.forEach((hub, index) => {
                                this.$hiltiApplicationsDialog.find('#hubs').append(hiltiApplicationsAddLinksDialogTemplate({
                                    Id: hub.Id,
                                    Name: hub.Name,
                                    Index: index,
                                    Regions: hub.Regions.map(regionCl => ({
                                        Id: regionCl.Id,
                                        Name: regionCl.Name,
                                        HubId: regionCl.HubId,
                                        Index: index,
                                        Link: hiltiApplication.Links.find(y => y.RegionId == regionCl.Id && y.LcId == 0)?.Link,
                                        Enabled: hiltiApplication.Links.find(y => y.RegionId == regionCl.Id && y.LcId == 0)?.Active ? 'checked' : '',
                                        RegionLanguages: this.regionLanguageCodeList.filter(y => y.RegionId == regionCl.Id).map(z => ({
                                            Id: z.LcId,
                                            Name: z.Name,
                                            LcId: z.LcId,
                                            RegionId: z.RegionId,
                                            Enabled: hiltiApplication.Links.find(k => k.RegionId == regionCl.Id && k.LcId == z.LcId)?.Active ? 'checked' : '',
                                            Link: hiltiApplication.Links.find(k => k.RegionId == regionCl.Id && k.LcId == z.LcId)?.Link
                                        }))
                                    }))
                                }));
                            });

                            this.$hiltiApplicationsLinksDialog.html(compiledHtmlLinks);

                            this.$hiltiApplicationsDialog.dialog({
                                title: 'Edit hilti application',
                                width: '1000px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        click: async () => {
                                            this.$hiltiApplicationsDialog.dialog('close');
                                            await this.releaseResources();
                                        }
                                    },
                                    {
                                        text: 'Save',
                                        click: async () => {
                                            const $form = this.$hiltiApplicationsDialog.find('form');
                                            const form = $form[0] as any;

                                            if (form.reportValidity == null || form.reportValidity()) {
                                                const appImage = this.image ?? hiltiApplication.Image;
                                                if (ImageHelper.isImageEmpty(appImage)) {
                                                    this.modalService.showMessage('Please select application image.', 'No image specified');
                                                    return;
                                                }

                                                const data: HiltiApplication = {
                                                    Id: item.Id,
                                                    Active: form.elements['active'].checked,
                                                    DisplayKey: form.elements['applicationName'].value,
                                                    SubtitleKey: form.elements['applicationSubtitle'].value,
                                                    SortOrder: item.SortOrder,
                                                    Image: appImage,
                                                    ShowNewLabel: form.elements['showNewLabel'].checked,
                                                    PerHubAccess: form.elements['activatedPerHub'].checked,
                                                    Url: form.elements['perUserAccessUrl'].value,
                                                    UserAccessEmails: form.elements['userAccessEmails'].value,
                                                    Links: this.getAppLinks($form)
                                                };

                                                await this.productInformationApiService.hiltiApplicationsUpdateApplications(data);

                                                this.$hiltiApplicationsDialog.dialog('close');
                                                await this.releaseResources();
                                            }
                                        }
                                    }
                                ],
                                open: () => {
                                    this.$hiltiApplicationsDialog.find('#hubsFilter')
                                        .chosen({ width: '100%', placeholder_text_multiple: 'Select hubs ...' })
                                        .on('change', (eventObject, args) => this.hubsFilterChange(eventObject, args, null, true));
                                    this.$hiltiApplicationsDialog.find('#image').on('change', (eventObject, args) => this.handleImage());
                                    this.$hiltiApplicationsDialog.find('#applicationName').on('keyup', (e) => this.onApplicationNameTranslationKeyPartChange());
                                    this.$hiltiApplicationsDialog.find('#applicationSubtitle').on('keyup', (e) => this.onApplicationSubtitleTranslationKeyPartChange());
                                    this.$hiltiApplicationsDialog.find('#activatedPerHub').on('change', (e) => this.handleActivatedPerHubChanged());
                                },
                                close: async () => {
                                    this.$hiltiApplicationsDialog.empty();
                                    await this.releaseResources();
                                }
                            });
                        });

                        $deleteNode.click(() => {
                            const $deleteDialog = $('#deleteHiltiApplicationDialog');

                            $deleteDialog.dialog({
                                width: 500,
                                modal: true,
                                resizable: false,
                                draggable: false,
                                title: 'Delete Hilti application',
                                buttons: [{
                                    text: 'Cancel',
                                    click: () => {
                                        $deleteDialog.dialog('close');
                                    }
                                }, {
                                    text: 'Delete',
                                    click: async () => {
                                        await this.productInformationApiService.deleteHiltiApp(item.Id);

                                        $deleteDialog.dialog('close');
                                        await this.loadGridData();
                                    }
                                }]
                            });
                        });

                        return $mainNode;
                    }
                }
            ]
        });
    }

    public handleImage() {
        const file = document.querySelector<HTMLInputElement>("input[type=file]").files[0];
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                const image = reader.result as string;   // convert image file to base64 string
                (async () => {
                    try {
                        await ImageHelper.validateImageSize(
                            image,
                            128,
                            128
                        );

                        // success
                        this.image = image;
                        document.querySelector<HTMLInputElement>("#imagePreview").src = this.image;
                    }
                    catch (_) {
                        // error
                        this.modalService.showMessage('Image size MUST be 128x128 or smaller.', 'Wrong image size');
                        this.image = null;
                    }
                })();
            },
            false,
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    private async releaseResources() {
        this.image = null;
        await this.loadGridData();
    }

    private async loadGridData() {
        this.initialData = await this.productInformationApiService.getHiltiApplications();

        this.regions = this.codeListsService.regions;

        this.hubRegionCodeList = this.codeListsService.regions.map<HubRegionLink>(x => ({
            Id: x.Id,
            Name: x.Name,
            HubId: x.HubId,
            Link: '',
            Enabled: false
        }));

        this.hubCodeList = this.codeListsService.hubs
            .sort((h1, h2) => {
                if (h1.Name > h2.Name) {
                    return 1;
                }

                if (h1.Name < h2.Name) {
                    return -1;
                }

                return 0;
            }).map<Hub>(x => ({
                Id: x.Id,
                Name: x.Name,
                Regions: this.hubRegionCodeList.filter(y => y.HubId == x.Id)
            }));

        this.hubsFilter = this.hubCodeList.map(hub => ({
            HubId: hub.Id,
            Name: hub.Name,
            Selected: null
        }));

        this.regionLanguageCodeList = this.codeListsService.regionLanguages.map(x => ({
            Id: x.Id,
            Name: x.Name,
            LcId: x.LcId,
            RegionId: x.RegionId,
            DefaultForRegion: x.DefaultForRegion
        } as RegionLanguage));

        this.$hiltiApplicationsGrid.jsGrid({
            data: this.initialData
        });
    }

    private hubsFilterChange(eventObject: JQuery.TriggeredEvent<any, any, any, any>, args: Chosen.SelectedData, id: number, isNew: boolean) {
        if ("selected" in args) {
            // if new hub was selected in filter, add hub node to make hub editable
            const hubCl = this.hubCodeList.find(hub => hub.Id.toString() == args.selected);

            const numberOfChosenHubs = document.getElementById('hubsFilter_chosen').getElementsByClassName('search-choice').length;

            if(!isNew) {
                const hiltiApp = this.initialData.find(x => x.Id == id);

                this.$hiltiApplicationsDialog.find('#hubs').append(hiltiApplicationsAddLinksDialogTemplate({
                    Id: hubCl.Id,
                    Name: hubCl.Name,
                    Index: numberOfChosenHubs - 1,
                    Regions: hubCl.Regions.map(regionCl => ({
                        Id: regionCl.Id,
                        Name: regionCl.Name,
                        HubId: regionCl.HubId,
                        Index: numberOfChosenHubs - 1,
                        Link: hiltiApp.Links.find(y => y.RegionId == regionCl.Id && y.LcId == 0)?.Link,
                        Enabled: hiltiApp.Links.find(y => y.RegionId == regionCl.Id && y.LcId == 0)?.Active ? 'checked' : '',
                        RegionLanguages: this.regionLanguageCodeList.filter(y => y.RegionId == regionCl.Id && y.LcId != 0).map(z => ({
                            Id: z.LcId,
                            Name: z.Name,
                            LcId: z.LcId,
                            RegionId: z.RegionId,
                            Link: hiltiApp.Links.find(k => k.RegionId == regionCl.Id && k.LcId == k.LcId)?.Link,
                        }))
                    }))
                }));
            }
            else {
                this.$hiltiApplicationsDialog.find('#hubs').append(hiltiApplicationsAddLinksDialogTemplate({
                    Id: hubCl.Id,
                    Name: hubCl.Name,
                    Index: numberOfChosenHubs - 1,
                    Regions: hubCl.Regions.map(regionCl => ({
                        Id: regionCl.Id,
                        Name: regionCl.Name,
                        HubId: regionCl.HubId,
                        Index: numberOfChosenHubs - 1,
                        RegionLanguages: this.regionLanguageCodeList.filter(x => x.RegionId == regionCl.Id)
                    }))
                }));
            }

            this.selectedHubs.push(hubCl.Id);
        }
        else if (args.deselected != null) {
            // hub was removed from filter, remove it also from editable hubs
            const hubCl = this.hubCodeList.find(hub => hub.Id.toString() == args.deselected);
            this.$hiltiApplicationsDialog.find('#hiltiApplicationsAddLinkForm-' + args.deselected).remove();
            this.selectedHubs = this.selectedHubs.filter(x => x != hubCl.Id);
        }
    }

    private getAppLinks(form: JQuery<HTMLFormElement>) {
        const appLinks: HiltiApplicationLinks[] = [];

        for (let region of this.codeListsService.regions) {
            const elementIdBase = `#hiltiApplicationsAddLinkForm-${region.HubId}-Region-${region.Id}`;
            const elementLink = form.find(`${elementIdBase}-Url`);
            const elementEnabled = form.find(`${elementIdBase}-Enabled`);
            const link = elementLink[0] as any;
            const enabled = elementEnabled[0] as any;

            if(link?.value || enabled?.value) {
                const model: HiltiApplicationLinks = {
                    RegionId: region.Id,
                    Link: link.value,
                    Active: enabled.checked,
                    LcId: 0
                }

                appLinks.push(model);
            }

            for (let regionLanguage of this.regionLanguageCodeList.filter(x => x.RegionId == region.Id)) {
                const elementIdBase = `#hiltiApplicationsAddLinkForm-Region-${region.Id}`;
                const elementLanguageLink = form.find(`${elementIdBase}-LcId-${regionLanguage.LcId}-Url`);
                const elementLanguageEnabled = form.find(`${elementIdBase}-LcId-${regionLanguage.LcId}-Enabled`);

                for (let i = 0; i < elementLanguageLink.length; i++) {
                    const link = elementLanguageLink[i] as any;
                    const enabledLink = elementLanguageEnabled[i] as any;

                    if(link.value || enabledLink?.value) {
                        const model: HiltiApplicationLinks = {
                            RegionId: region.Id,
                            Link: link.value,
                            Active: enabledLink.checked,
                            LcId: regionLanguage.LcId
                        }

                        appLinks.push(model);
                    }
                }
            }
        }

        return appLinks;
    }

    private onApplicationNameTranslationKeyPartChange() {
        const inputName = this.$hiltiApplicationsDialog.find('#applicationName').val().toString();
        this.$hiltiApplicationsDialog.find('#hiltiApplicationApplicationName').text(this.getAppTranslationKey(inputName));
    }

    private onApplicationSubtitleTranslationKeyPartChange() {
        const inputSubTitle = this.$hiltiApplicationsDialog.find('#applicationSubtitle').val().toString();
        this.$hiltiApplicationsDialog.find('#hiltiApplicationApplicationSubtitle').text(this.getAppTranslationKey(inputSubTitle));
    }

    private getAppTranslationKey(appPart: string) {
        return `Agito.Hilti.Profis3.ProjectAndDesign.HiltiAplications.${appPart}`;
    }

    private handleActivatedPerHubChanged() {
        const $form = this.$hiltiApplicationsDialog.find('form');
        const form = $form[0] as any;
        const perHubAccess = form.elements['activatedPerHub'].checked;

        const accessSubform = this.$hiltiApplicationsDialog[0].querySelector<HTMLElement>('.access-subform');
        accessSubform.classList.remove(this.getAccessSubformClass(!perHubAccess));
        accessSubform.classList.add(this.getAccessSubformClass(perHubAccess));
    }

    private getAccessSubformClass(perHubAccess: boolean) {
        if (perHubAccess) {
            return 'hubs';
        }
        return 'emails';
    }
}
