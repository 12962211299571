var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <div id=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":55}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":12,"column":63},"end":{"line":12,"column":69}}}) : helper)))
    + "\" class=\"regions-item\">\n            <input id=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":13,"column":52},"end":{"line":13,"column":61}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":13,"column":69},"end":{"line":13,"column":75}}}) : helper)))
    + "-Id\"\n                name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":36}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":56}}}) : helper)))
    + "].RegionId\" type=\"hidden\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":29}}}) : helper)))
    + "\" />\n\n            <span class=\"regions-item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":52}}}) : helper)))
    + "</span>\n\n            <div class=\"regions-item-data\">\n                <div class=\"form-group row\">\n                    <label class=\"col-sm-4 col-form-label regions-item-label\" for=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":21,"column":112},"end":{"line":21,"column":121}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":21,"column":129},"end":{"line":21,"column":135}}}) : helper)))
    + "-Enabled\">Enabled</label>\n                    <div class=\"col-sm-4\">\n                        <input id=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":23,"column":64},"end":{"line":23,"column":73}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":23,"column":81},"end":{"line":23,"column":87}}}) : helper)))
    + "-Enabled\"\n                            name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":24,"column":39},"end":{"line":24,"column":48}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":24,"column":58},"end":{"line":24,"column":68}}}) : helper)))
    + "].Enabled\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":24,"column":86},"end":{"line":24,"column":97}}}) : helper)))
    + "\" type=\"checkbox\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":24,"column":115},"end":{"line":24,"column":126}}}) : helper)))
    + "\n                            class=\"regions-item-enabled\" />\n                    </div>\n                </div>\n\n                <div class=\"form-group row\">\n                    <label class=\"col-sm-4 col-form-label regions-item-label\" for=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":30,"column":112},"end":{"line":30,"column":121}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":30,"column":129},"end":{"line":30,"column":135}}}) : helper)))
    + "-Url\">Default link</label>\n                    <div class=\"col-sm-6\">\n                        <input id=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":32,"column":64},"end":{"line":32,"column":73}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":32,"column":81},"end":{"line":32,"column":87}}}) : helper)))
    + "-Url\"\n                            name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":33,"column":39},"end":{"line":33,"column":48}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":33,"column":58},"end":{"line":33,"column":68}}}) : helper)))
    + "].Link\" type=\"url\" maxlength=\"1024\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Link") || (depth0 != null ? lookupProperty(depth0,"Link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Link","hash":{},"data":data,"loc":{"start":{"line":33,"column":111},"end":{"line":33,"column":119}}}) : helper)))
    + "\"\n                            class=\"form-control regions-item-url\" />\n                    </div>\n                </div>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"RegionLanguages") || (depth0 != null ? lookupProperty(depth0,"RegionLanguages") : depth0)) != null ? helper : alias2),(options={"name":"RegionLanguages","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":56,"column":36}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"RegionLanguages")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group row\">\n                    <label class=\"col-sm-4 col-form-label regions-item-label\" for=\"hiltiApplicationsAddLinkForm-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":40,"column":119},"end":{"line":40,"column":131}}}) : helper)))
    + "-LcId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":40,"column":137},"end":{"line":40,"column":145}}}) : helper)))
    + "-Enabled\">Enabled</label>\n                    <div class=\"col-sm-4\">\n                        <input id=\"hiltiApplicationsAddLinkForm-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":42,"column":71},"end":{"line":42,"column":83}}}) : helper)))
    + "-LcId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":42,"column":89},"end":{"line":42,"column":97}}}) : helper)))
    + "-Enabled\"\n                            name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":43,"column":39},"end":{"line":43,"column":48}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":43,"column":58},"end":{"line":43,"column":68}}}) : helper)))
    + "].Enabled\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":43,"column":86},"end":{"line":43,"column":97}}}) : helper)))
    + "\" type=\"checkbox\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":43,"column":115},"end":{"line":43,"column":126}}}) : helper)))
    + "\n                            class=\"regions-item-enabled\" />\n                    </div>\n                </div>\n\n                <div class=\"form-group row\">\n                    <label class=\"col-sm-4 col-form-label regions-item-label\" for=\"hiltiApplicationsAddLinkForm-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":49,"column":119},"end":{"line":49,"column":131}}}) : helper)))
    + "-LcId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":49,"column":137},"end":{"line":49,"column":145}}}) : helper)))
    + "-Url\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":49,"column":151},"end":{"line":49,"column":159}}}) : helper)))
    + "</label>\n                    <div class=\"col-sm-6\">\n                        <input id=\"hiltiApplicationsAddLinkForm-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":51,"column":71},"end":{"line":51,"column":83}}}) : helper)))
    + "-LcId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":51,"column":89},"end":{"line":51,"column":97}}}) : helper)))
    + "-Url\"\n                            name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":52,"column":39},"end":{"line":52,"column":48}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":52,"column":58},"end":{"line":52,"column":68}}}) : helper)))
    + "].Link\" type=\"url\" maxlength=\"1024\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Link") || (depth0 != null ? lookupProperty(depth0,"Link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Link","hash":{},"data":data,"loc":{"start":{"line":52,"column":111},"end":{"line":52,"column":119}}}) : helper)))
    + "\"\n                            class=\"form-control regions-item-url\" />\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":44}}}) : helper)))
    + "\" class=\"hubs-item\">\n    <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":31}}}) : helper)))
    + "].HubId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":67}}}) : helper)))
    + "\" />\n\n    <div data-toggle=\"collapse\" data-target=\"#hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":4,"column":75},"end":{"line":4,"column":81}}}) : helper)))
    + "-Regions\"\n        class=\"collapse-toggle collapsed\">\n        <span class=\"collapse-toggle-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":6,"column":43},"end":{"line":6,"column":51}}}) : helper)))
    + "</span>\n        <span class=\"collapse-toggle-icon\"></span>\n    </div>\n\n    <div id=\"hiltiApplicationsAddLinkForm-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":48}}}) : helper)))
    + "-Regions\" class=\"collapse-container collapse\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Regions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":59,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});